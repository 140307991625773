import * as Focus from '../common/focus';

window.addEventListener('load', function () {
  const toggleSearch = document.querySelector('.toggleSearch');
  const searchModal = document.querySelector('#search-modal');
  const closeSearch = document.querySelector('#search-close');

  function closeSearchModal() {
    searchModal.classList.remove('opened');
    document.querySelector('body').classList.remove('search-modal-opened');
    toggleSearch.setAttribute('aria-expanded', 'false');
    closeSearch.setAttribute('aria-expanded', 'false');

    if (searchModal.classList.contains('search-modal--window')) {
      Focus.untrapFocusInside(jQuery('#search-modal'));
      document.documentElement.classList.remove('lockedPosition');
    }
  }

  function openSearchModal() {
    const openEvent = new Event('opensearchmodal');
    searchModal.classList.add('opened');
    document.querySelector('body').classList.add('search-modal-opened');
    toggleSearch.setAttribute('aria-expanded', 'true');
    closeSearch.setAttribute('aria-expanded', 'true');
    window.dispatchEvent(openEvent);

    if (searchModal.classList.contains('search-modal--window')) {
      Focus.trapFocusInside(jQuery('#search-modal'));
      document.documentElement.classList.add('lockedPosition');
    }
  }

  if (toggleSearch && closeSearch) {
    toggleSearch.addEventListener('click', function () {
      if (searchModal.classList.contains('opened')) {
        closeSearchModal();
      } else {
        openSearchModal();
      }
    });

    closeSearch.addEventListener('click', function () {
      if (searchModal.classList.contains('opened')) {
        closeSearchModal();
      }
    });

    // Close modal when clicking outside of it
    document.addEventListener('click', function (event) {
      if (
        searchModal.classList.contains('opened') &&
        !searchModal.contains(event.target) &&
        !toggleSearch.contains(event.target)
      ) {
        closeSearchModal();
      }
    });

    // Close modal clicking esc
    document.addEventListener('keydown', function (event) {
      if (searchModal.classList.contains('opened') && event.key === 'Escape') {
        closeSearchModal();
      }
    });
  }
});
